<template>
  <div class="login">
    <div class="container">
      <div class="title">
        Login to <img :alt="appName"
                      :src="require('@/assets/images/logos/pulsetic.svg')" />
      </div>
      <div class="login-card">

              <form class="mb-2"
                    @submit.prevent="validateOneTimeCode">
                <base-textbox label="One time code"
                              ref="otc"
                              type="text"
                              rules="required"
                              v-model="oneTimeCode"
                              placeholder="Enter the one time code" />
                <base-button type="submit"
                             class="submit-button"
                             color="primary"
                             size="big"
                             :loading="loading">Submit</base-button>

                <base-alert v-if="error"
                            class="alert-login-error"
                            type="error">{{ error }}</base-alert>
              </form>

      </div>
      <div class="login-footer">
        <a href="https://pulsetic.com/terms/">Terms</a> &nbsp; • &nbsp; <a href="https://pulsetic.com/privacy/">Privacy</a>
      </div>
    </div>

    <a class="back-to-home" href="https://pulsetic.com/">←  To <span>Home Page</span></a>
  </div>
</template>

<script>
import auth from '@/services/auth'
import store from '@/store/index.js'
export default {
  data () {
    return {
      loading: false,
      oneTimeCode: null,
      token: this.$route.params.token,
      loginType: this.$route.params.loginType,
      error: null,
      appName: process.env.VUE_APP_BRAND_NAME
    }
  },

  created () {
  },

  methods: {
    async validateOneTimeCode () {
      try {
        const response = await auth.validateOneTimeCode(this.oneTimeCode, this.token, this.loginType)
        store.commit('authentication/setAuthenticated', true)
        store.commit('authentication/setUser', response.user)
        store.commit('authentication/setToken', response.access_token)
        window.location.href = '/'
      } catch (e) {
        this.error = 'Something went wrong'
      }
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background: #f9f9f9;
  .container {
    max-width: 446px;
    padding: 0px;
  }

  @media (max-width: 500px) {
    .container {
      width: 100%;
      max-width: none;
      padding: 0 20px;
    }
  }

  .title {
    margin-bottom: 20px;

    font-size: 21px;
    font-weight: 500;

    width: 100%;
    text-align: center;
    letter-spacing:  -0.017em;

    img {
      height: 28px;
      vertical-align: text-bottom;
    }
  }

  .login-card {
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 10px 30px rgba(0, 0, 0, 0.05);
    border: 1px solid #E4E8ED;
  }

  .login-footer {
    font-size: 14px;
    color: #828282;
    text-align: center;
    padding: 30px;

    a {
      color: #828282;

      &:hover {
        color: map-get($colors, purple-1);
      }
    }
  }

  .back-to-home {
    color: #333;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    padding: 22px;
    span {
      font-weight: 700;
    }

    &:hover {
      color: map-get($colors, purple-1);
    }
  }
}
</style>
